<script setup>
import { ref } from 'vue';
import { useSettingsStore } from '~/stores/settings.store';
import { useMetaStore } from '~/stores/meta.store';

const props = defineProps({
    data: { type: Object, required: true },
    meta: {type: Object, required: true}
})

const settingStore = useSettingsStore()
const whatsapp = settingStore.settings.social_media_whatsapp;

const metaStore = useMetaStore();
const meta = ref({});
metaStore.getMetaTags()

meta.value = metaStore.metaGetter();
metaStore.setMeta({...props.meta});
</script>

<template>
	<LayoutAppHead></LayoutAppHead>
    <NuxtLayout name="desktop-default">
        <main>
            <section v-if="data">

                <div class="relative mx-auto mb-2">
                    <div class="relative h-40"></div>
                    <div class="absolute inset-0 bg-indigo-950 opacity-50 rounded-md"></div>
                    <div class="absolute bottom-1/4 p-5 flex flex-col justify-center items-center w-full">
                        <h2 class="text-white text-3xl font-medium tracking-wide mb-5">Agent Details</h2>
                    </div>
                </div>

                <div class="container mx-auto max-w-7xl px-4 sm:px-2 lg:px-8 mt-3 mb-8">

                    <div class="grid grid-cols-2 gap-4 items-center my-8">
                        <div>
                            <img :src="data.picture" alt="" class="w-4/5 object-cover rounded-xl">
                        </div>
                        <div class="flex flex-col">
                            <p class=" text-gray-500 text-sm mb-2">{{data.position}}</p>
                            <div class=" text-indigo-900 text-3xl font-medium mb-3">{{ data.name }}</div>
                            <p v-for="(lang, i) in data.supported_languages"
                                class=" text-gray-500 text-sm mb-2 italic">
                                {{ lang.name }}
                            </p>
                            <div class=" text-black/80 text-base leading-6 mt-4 mb-6" v-html="data.description">
                            </div>
                            <div class=" text-indigo-900 text-2xl font-medium mb-3">Contact this agent</div>
                            <div class="flex gap-3 items-center">
                                <button type="button"
                                    class="text-white px-4 py-2 bg-indigo-900 rounded justify-center items-center gap-2.5 inline-flex hover:bg-indigo-800">
                                    <i class="fa fa-envelope"></i>
                                    <span class="text-white text-base">Email</span>
                                </button>
                                <button :href="whatsapp" type="button"
                                    class="text-white px-4 py-2 bg-green-500 rounded justify-center items-center gap-2.5 inline-flex hover:bg-green-600/85">
                                    <i class="fab fa-whatsapp fa-xl"></i>
                                    <span class="text-base">Whatsapp</span>
                                </button>
                            </div>
                        </div>
                    </div>

                </div>
            </section>
        </main>
    </NuxtLayout>
</template>